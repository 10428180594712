import {Link} from 'gatsby'

import React from 'react'

import { getPageUrl } from '../lib/helpers'

import * as styles from './breadcrumb.module.css'

function Breadcrumb (props) {
  return (
    <div className={styles.breadcrumbSection}>
            <Link className={styles.breadcrumbLink} to="/">{props.home}</Link>
            { // show site_section (aka page_type) as long as no parent
              !props.thisPage.parent_page?.slug && props.thisPage.page_type?.slug && (<> {props.separator} <Link className={styles.breadcrumbLink} to={`/${props.thisPage.page_type?.slug}/`}>{props.thisPage.page_type?.name}</Link> </>)}
            { // show grandparent 
              props.thisPage.parent_page?.include_parent_when_in_breadcrumb_as_parent && props.thisPage.parent_page.parent_page && (<> {props.separator} <Link className={styles.breadcrumbLink} to={`/${props.thisPage.parent_page.parent_page.slug}/`}>{props.thisPage.parent_page.parent_page.title_short}</Link> </>)
            }
            { props.thisPage.parent_page?.slug && (<> {props.separator} <Link className={styles.breadcrumbLink} to={`/${props.thisPage.parent_page?.slug}/`}>{props.thisPage.parent_page.title_short ? props.thisPage.parent_page.title_short : props.thisPage.parent_page?.title}</Link> </>)}
            { props.thisPage?.title_short && (<> {props.separator} {props.thisPage?.title_short}</>)}

    </div>
  )
}

Breadcrumb.defaultProps = {
  home: 'Home',
  thisPage: '',
  separator: ">"
}

export default Breadcrumb
